/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Container } from "react-bootstrap"

import "@fontsource/karla"
import "@fontsource/karla/600.css"
import "@fontsource/karla/700.css"
import "@fontsource/spectral/700.css"

import Header from "./header"
import Hero from "./hero"
import Footer from "./footer"

import { GlobalStyle } from "../styles/styles"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
          integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
          crossorigin="anonymous"
        />
      </Helmet>

      <GlobalStyle />

      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />

      <Hero />

      <Container as="main">{children}</Container>

      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
