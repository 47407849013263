import * as React from "react"
import styled from "styled-components"
import { Container } from "react-bootstrap"
import { Whatsapp } from "@styled-icons/bootstrap/Whatsapp"
import { StaticImage } from "gatsby-plugin-image"
import Fade from "react-reveal/Fade"

import bg from "../images/bg-dark.png"

const Wrapper = styled.div`
  /* background-color: #f5faff; */
  background: url(${bg}) no-repeat center center;
  background-size: cover;
`

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;

  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding-top: 50px;
    padding-bottom: 50px;
  }
`

const HeroContent = styled.div`
  max-width: 400px;
  background-color: transparent;

  h2 {
    font-size: 1.25rem;
  }

  h1 {
    font-size: 5rem;
    margin: 15px auto;
  }

  p {
    font-weight: 600;
    font-size: 1.25rem;
  }

  @media (max-width: 425px) {
    h1 {
      font-size: 3rem;
    }
  }
`

const HeroImg = styled.div`
  max-width: 500px;
`

const StyledButton = styled.a`
  background: #002249;
  background: -webkit-linear-gradient(to right, #002249, #0c59db);
  background: linear-gradient(to right, #002249, #0c59db);
  border-color: #002249;
  transition: 0.3s;

  &:hover {
    background: -webkit-linear-gradient(to right, #0c59db, #002249);
    background: linear-gradient(to right, #0c59db, #002249);
    border-color: #0c59db;
  }

  svg {
    fill: #ffffff;
  }

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`

const Hero = () => (
  <Wrapper>
    <StyledContainer>
      <HeroContent>
        <Fade bottom delay={500}>
          <h2>ConecTimme</h2>
          <h1>Elétrica & Eletrônica</h1>
          <p>
            Soluções inteligente para você, para sua família e para sua empresa.
          </p>
        </Fade>

        <Fade bottom delay={800}>
          <StyledButton
            className="btn btn-primary"
            href="https://wa.me/5519995938158"
          >
            <Whatsapp className="mx-1" size={16} />
            Entre em contato conosco!
          </StyledButton>
        </Fade>
      </HeroContent>

      <HeroImg>
        <StaticImage
          src="../images/banner.png"
          quality={100}
          formats={["AUTO", "WEBP", "AVIF"]}
          placeholder="tracedSVG"
          alt="Estante de aço"
        />
      </HeroImg>
    </StyledContainer>
  </Wrapper>
)

export default Hero
