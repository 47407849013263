import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  * {
    font-family: "Karla", sans-serif;
    font-weight: 400;
    color: #ffffff;
  }
  html {
    scroll-behavior: smooth;
  }
  body {
    padding-top: 91px;
    background-color: #222222 !important;
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: "Spectral", serif;
    font-weight: 700;
    color: #ffffff;
    ${"" /* color: #0c59db; */}
  }
  p, li {
    color: #ADADAD;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  svg {
    background-color: transparent;
  }
`
